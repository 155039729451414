<template>
  <Drawer :closable="false" v-model="functionSettingsDrawer" @on-visible-change="visibleChange">
    <Divider size="small">{{ $t('key1000244') }}</Divider>
    <div class="settings_item" v-for="(item, index) in functionSettingList" :key="index">
      <h3 class="title">{{ item.title }}</h3>
      <i-switch size="small" v-model="item.value" @on-change="(value)=>switchChange(value, item.key)"></i-switch>
    </div>
  </Drawer>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "functionSettings",
  mixins: [Mixin],
  data() {
    return {
      functionSettingsDrawer: false,
      functionSettingList: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000245'),
          key: 'switchSidebarMenu',
          value: false
        }
      ]
    };
  },
  created() {
    this.defaultOpen();
  },
  methods: {
    // 默认开启功能设置
    defaultOpen() {
      let v = this;
      // 默认要开启功能设置的key
      let keyList = ['switchSidebarMenu'];
      let openKey = [];
      v.functionSettingList.map((ele) => {
        ele.value = false;
        if (keyList.includes(ele.key)) {
          let talg = localStorage.getItem(ele.key);
          if (!talg) {
            ele.value = true;
            localStorage.setItem(ele.key, JSON.stringify(ele.value));
            openKey.push(ele);
          }
        }
      });
      if (openKey.length > 0) {
        v.$emit('updateData', true);
      }
    },
    // 切换功能设置
    switchChange(value, key) {
      let v = this;
      v.functionSettingList.map((item) => {
        if (item.key === key) {
          item.value = value;
        }
      });
      localStorage.setItem(key, JSON.stringify(value));
      this.$emit('updateData', true);
    },
    // 监听抽屉的状态
    visibleChange(value) {
      let v = this;
      if (value) {
        v.functionSettingList.map((item) => {
          let talg = localStorage.getItem(item.key);
          if (talg) {
            item.value = JSON.parse(talg);
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.settings_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  .title {
    font-size: 14px;
    color: #666;
  }
}
</style>
